import { WorkflowReviewStage } from 'api-clients/monolith';
import React, { VFC } from 'react';

import { StageSection } from 'containers/WorkflowEditor/components/StageSection';

import { IdleMoveRule } from '../../../IdleMoveRule';
import { Settings } from './Settings';

export interface ReviewStageProps {
  stage: WorkflowReviewStage;
}

export const ReviewStage: VFC<ReviewStageProps> = ({ stage }) => {
  return (
    <div data-testid="ReviewStage">
      <StageSection>
        <Settings stage={stage} />
      </StageSection>
      <StageSection>
        <IdleMoveRule stage={stage} />
      </StageSection>
    </div>
  );
};
