import { defineMessages } from 'react-intl';

export const messages = defineMessages({
  review: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.review`,
    defaultMessage: 'Review',
  },
  reviewHeader: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.reviewHeader`,
    defaultMessage:
      "Configure what you'd like your frontline workforce to see when a candidate shows up for review.",
  },
  hiringManagerInstructionsHeader: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.hiringManagerInstructionsHeader`,
    defaultMessage: 'Hiring manager instructions',
  },
  optional: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.optional`,
    defaultMessage: '(optional)',
  },
  hiringManagerInstructionsText: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.hiringManagerInstructionsText`,
    defaultMessage:
      'Provided instructions to a Hire Go user to prepare them for review candidates',
  },
  hiringManagerInstructionsPlaceHolder: {
    id: `app.containers.WorkflowEditor.components.WorkflowEditorContent.StageDetail.reviewStage.hiringManagerInstructionsPlaceHolder`,
    defaultMessage: 'Provide instructions to a Hire Go user',
  },
});
