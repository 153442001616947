import { makeStyles } from '@material-ui/core/styles';

const baseText = {
  fontSize: '14px',
  fontWeight: 450,
};

const baseTextDark = {
  color: '#131E40',
};

const baseTextLight = {
  color: '#666F89',
};

export const useStyles = makeStyles({
  reviewHeader: {
    ...baseText,
    ...baseTextDark,
    lineHeight: '20px',
    marginBottom: '24px',
  },
  hiringManagerInstructionsHeader: {
    ...baseText,
    ...baseTextDark,
    lineHeight: '16px',
    marginRight: '3px',
  },
  optional: {
    ...baseText,
    ...baseTextLight,
    lineHeight: '16px',
  },
  hiringManagerInstructionsText: {
    ...baseText,
    marginTop: '4px',
    marginBottom: '12px',
  },
});
