import { Typography } from '@material-ui/core';
import { WorkflowReviewStage } from 'api-clients/monolith';
import produce from 'immer';
import React, { useContext, VFC } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';

import TextEditor from 'components/TextEditor/Loadable';
import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageSettingCard } from '../../../StageSettingCard';
import { messages } from './messages';
import { useStyles } from './styles';

export const Settings: VFC<{ stage: WorkflowReviewStage }> = ({ stage }) => {
  const intl = useIntl();
  const styles = useStyles();
  const { setStage } = useContext(StageContext);

  const onHiringInstructionsChange = (instructions: string) => {
    setStage(
      produce(stage, draftStage => {
        draftStage.additional_info.hiring_manager_instructions = instructions;
      }),
    );
  };

  return (
    <div data-testid="hiredStage">
      <StageSettingCard
        variant="default"
        title={intl.formatMessage(messages.review)}
      >
        <Typography className={styles.reviewHeader}>
          <FormattedMessage {...messages.reviewHeader} />
        </Typography>
        <>
          <Typography
            display="inline"
            className={styles.hiringManagerInstructionsHeader}
          >
            <FormattedMessage {...messages.hiringManagerInstructionsHeader} />
          </Typography>
          <Typography display="inline" className={styles.optional}>
            <FormattedMessage {...messages.optional} />
          </Typography>
        </>
        <Typography className={styles.hiringManagerInstructionsText}>
          <FormattedMessage {...messages.hiringManagerInstructionsText} />
        </Typography>
        <TextEditor
          id="stage-instructions"
          onChange={onHiringInstructionsChange}
          placeholder={intl.formatMessage(
            messages.hiringManagerInstructionsPlaceHolder,
          )}
          value={stage.additional_info.hiring_manager_instructions}
          disableImageUpload
          enableTextStyleSelect={false}
        />
      </StageSettingCard>
    </div>
  );
};
