import { Alert } from '@fountain/fountain-ui-components';
import { FunnelDetails, WorkflowStageDetail } from 'api-clients/monolith';
import React, { FC, useContext, useEffect, useRef } from 'react';
import { FormattedMessage } from 'react-intl';

import { StageContext } from 'containers/WorkflowEditor/contexts/stageContext';

import { StageDetailFooter } from '../../StageDetailFooter';
import { ArchivedStage } from './ArchivedStage';
import { AssessmentStage } from './AssessmentStage';
import { BackgroundCheckStage } from './BackgroundCheckStage';
import { CustomStage } from './CustomStage';
import { DataCollectionStage } from './DataCollectionStage';
import { DistributeApplicantsRuleStage } from './DistributeApplicantsRuleStage';
import { DocumentSigningStage } from './DocumentSigningStage';
import { HiredStage } from './HiredStage';
import { I9FormStage } from './I9FormStage';
import { JobSelectorStage } from './JobRouterStages/JobSelectorStage';
import { JobSwitcherStage } from './JobRouterStages/JobSwitcherStage';
import { LearningStage } from './LearningStage';
import { messages } from './messages';
import { MultiStage } from './MultiStage';
import { PartnerStage } from './PartnerStage';
import { RejectedStage } from './RejectedStage';
import { ReviewStage } from './ReviewStage';
import { RuleStage } from './RuleStage';
import {
  EventStage,
  SchedulerStage,
  SchedulerStageZero,
} from './SchedulerStage';
import { SchoolkeepStage } from './SchoolkeepStage';
import { useStyles } from './styles';
import { TechCheckStage } from './TechCheckStage';
import { UnsupportedStage } from './UnsupportedStage';
import { VideoRecordingStage } from './VideoRecordingStage';
import { W4Stage } from './W4Stage';

interface StageContainerProps {
  opening: FunnelDetails;
  isHelloSignProvider?: boolean;
  stage: WorkflowStageDetail;
  dataCollectionBotSaveError?: string | null;
}

const StageContainer: FC<StageContainerProps> = ({
  isHelloSignProvider,
  opening,
  stage,
  dataCollectionBotSaveError,
}) => {
  switch (stage.type) {
    case 'ArchivedStage':
      return <ArchivedStage stage={stage} />;
    case 'AccurateStage':
    case 'BackgroundCheckerStage':
    case 'SterlingStage':
      return <BackgroundCheckStage stage={stage} />;
    case 'CustomStage':
      return <CustomStage stage={stage} />;
    case 'AssessmentStage':
      return <AssessmentStage stage={stage} />;
    case 'DataCollectionStage':
      return (
        <DataCollectionStage
          stage={stage}
          dataCollectionBotSaveError={dataCollectionBotSaveError}
        />
      );
    case 'DistributeApplicantsRuleStage':
      return <DistributeApplicantsRuleStage stage={stage} />;
    // DocumentSigningStage handles multiple stage types.
    // `DocumentSigningStage` === fountain whitelabeled hellosign
    // `DocumentSignatureStage` can be docusign or the deprecated standalone HelloSign
    case 'DocumentSigningStage':
    case 'DocumentSignatureStage':
      if (stage.type === 'DocumentSignatureStage' && isHelloSignProvider) {
        return (
          <UnsupportedStage
            bodyText={
              <FormattedMessage
                {...messages.unsupportedStage}
                values={{ stageType: 'HelloSign Document Signing Stage' }}
              />
            }
          />
        );
      }
      return <DocumentSigningStage stage={stage} />;
    case 'EventStage':
      return (
        <EventStage stage={stage} openingExternalId={opening.external_id} />
      );
    case 'I9FormStage':
      return <I9FormStage stage={stage} />;
    case 'JobSelectorStage':
      return <JobSelectorStage stage={stage} />;
    case 'JobSwitcherStage':
      return (
        <JobSwitcherStage stage={stage} workflowId={opening.workflow?.id} />
      );
    case 'LearningStage':
      return <LearningStage stage={stage} />;
    case 'HiredStage':
      return <HiredStage stage={stage} />;
    case 'MultiStage':
      return <MultiStage opening={opening} stage={stage} />;
    case 'PartnerStage':
      return (
        <PartnerStage
          stage={stage}
          workflowId={opening.workflow?.external_id}
        />
      );
    case 'RejectedStage':
      return <RejectedStage stage={stage} />;
    case 'RuleStage':
      return <RuleStage stage={stage} />;
    case 'SchedulerStage':
      return (
        <SchedulerStage stage={stage} openingExternalId={opening.external_id} />
      );
    case 'SchedulerV2Stage':
      return (
        <SchedulerStageZero
          stage={stage}
          address={opening.location?.address}
          openingExternalId={opening.external_id}
        />
      );
    case 'SchoolkeepStage':
      return <SchoolkeepStage stage={stage} />;
    case 'TechCheckStage':
      return <TechCheckStage stage={stage} />;
    case 'VideoRecordingStage':
      return <VideoRecordingStage stage={stage} />;
    case 'W4FederalFormStage':
    case 'W4StateFormStage':
      return <W4Stage stage={stage} />;
    case 'ReviewStage':
      return <ReviewStage stage={stage} />;
    default:
      return (
        <UnsupportedStage
          bodyText={
            <FormattedMessage
              {...messages.stageComingSoon}
              values={{ stageType: stage.type }}
            />
          }
        />
      );
  }
};

export const StageContainerByType: FC<{
  opening: FunnelDetails;
}> = ({ opening }) => {
  const classes = useStyles();
  const contentRef = useRef<HTMLDivElement>(null);

  const { stage, updateStageResult } = useContext(StageContext);

  useEffect(() => {
    if (!contentRef.current) {
      return;
    }

    if (contentRef.current.scrollTo) {
      contentRef.current.scrollTo(0, 0);
    } else {
      // NOTE: Older versions of MS Edge do not support .scrollTo, instead use .scrollTop to cover that case.
      contentRef.current.scrollTop = 0;
    }
  }, [stage.external_id]);

  return (
    <div className={classes.stageContainer} ref={contentRef}>
      {updateStageResult.isError && updateStageResult.error && (
        <Alert fullWidth type="danger" className={classes.errorsAlert}>
          <FormattedMessage {...messages.errorsPresent} />
        </Alert>
      )}
      <StageContainer
        isHelloSignProvider={
          (stage.type === 'DocumentSignatureStage' &&
            stage.additional_info
              .is_document_signature_stage_hellosign_provider) ??
          false
        }
        opening={opening}
        stage={stage}
        dataCollectionBotSaveError={
          stage.type === 'DataCollectionStage' &&
          updateStageResult.isError &&
          updateStageResult.error &&
          updateStageResult.error.errors?.base
            ? updateStageResult.error.errors.base[0]
            : null
        }
      />
      <StageDetailFooter externalId={stage.external_id} />
    </div>
  );
};
