import { ApiSelfServeV1Service } from 'api-clients/monolith';
import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';

import { addDefaultErrorMessageActionI18n } from 'containers/FlashMessage/actions';

export default function useJob(jobId) {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState(null);
  const intl = useIntl();

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const res = await ApiSelfServeV1Service.getApiSelfServeV1Jobs(jobId);
        if (res.job) {
          setData(res.job);
        }
      } catch (e) {
        dispatch(addDefaultErrorMessageActionI18n(intl));
      } finally {
        setIsLoading(false);
      }
    };

    if (jobId) fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [jobId]);

  return { data, isLoading };
}
